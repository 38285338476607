<template>
  <div class="FormLogin">
    <v-form lazy-validation ref="formLogin" v-model="valid">
      <v-row no-gutters>
        <v-col>
          <v-text-field
            label="Usuario"
            hide-details="auto"
            v-model="user"
            color="black"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-10">
        <v-col>
          <v-text-field
            label="Contraseña"
            type="password"
            hide-details="auto"
            v-model="pass"
            color="black"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-10" align="center" @click="login()">
        <v-col cols="auto">
          <span class="titles">Ingresar</span>
          {{userSession.user}}
        </v-col>
        <v-col cols="auto">
          <v-img
            src="@/assets/icons/derecha.svg"
            class="ml-5 btnSend-div__arrow"
          ></v-img>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      valid: true,
      user: "",
      pass: "",
      userSession: ''
    };
  },   
  methods: {
    validate: function () {
      if(this.$refs.formLogin.validate()){
        this.login()
      }
    },
    login: function () {
      var aux = this;

      //fetch para el login
      fetch(
        "https://www.ofisdesigncms.actstudio.xyz/api/cockpit/authUser?token=e96ec97538f5b51ad80b44386e6d1c",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user: this.user,
            password: this.pass,
          }),
        }
      )
        .then((user) => user.json())
        .then((user) => {
            aux.$session.start()
            aux.$session.set('user', user)
            aux.$emit('update')
            aux.$router.push('/projectsClients')
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>