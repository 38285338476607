<template>
  <div class="Login">
    <v-container fluid class="py-0 animation" style="animation-delay: 2s">
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="Contact__column-2 position-relative padding-custom-contact"
        >
          <v-row no-gutters justify="center" class="fill-height">
            <v-col cols="12" sm="7" class="px-8 px-sm-12 px-md-3">
              <span class="d-block titles aeonik-bold">
                Portal de clientes
              </span>
              <p class="mt-8">
                En este sitio podras encontrar todos los avances y archivos
                descargables relacionados con tu proyecto.
              </p>
              <p>
                Para tener acceso, completa los campos con los datos brindados
                por el equipo de <b>Ofis Design</b>
              </p>
              <!-- form login -->
              <FormLogin class="mt-8"></FormLogin>
              <!-- /form login -->
            </v-col>

            <v-col cols="12" sm="7" class="px-8 px-sm-12 px-md-3">
              <v-row
                class="Contact__contact-div"
                no-gutters
                justify="space-between"
                align="center"
              >
                <v-col cols="auto">
                  <a
                    href="'mailto:' + contactSingleton.card_contact.email"
                    class="not-text-decoration color-black"
                  >
                    <span class="d-block titles-card-projects"></span>
                  </a>

                  <a
                    target="_blank"
                    href=""
                    class="not-text-decoration color-black"
                  >
                    <span class="d-block titles-card-projects"
                      >Avenida Roble No .525 - SPGG, NL.</span
                    >
                  </a>

                  <a
                    target="_blank"
                    href=""
                    class="not-text-decoration color-black"
                  >
                    <span class="d-block titles-card-projects"
                      >ofisdesign.com.mx</span
                    >
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pa-0" cols="12" md="6">
          <v-img
            src="@/assets/img/login/login.jpg"
            class="Contact__img"
          ></v-img>
          <!-- <template v-if="contactSingleton.cover != null">
        </template> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import FormLogin from "@/components/Login/Form";

export default {
  name: "Login",
  components: {
    FormLogin,
  },
  mounted() {
    this.$emit("update", 1);
  },
};
</script>